<template>
    <div class="paysucc_box">
        <div class="icon">
            <img src="./image/paysuccess.png" alt="" />
        </div>
        <p class="dec">您已支持成功{{ amount / 100 }}元</p>
        <p class="dec">
            感谢您的爱心！
        </p>
        <button class="share-btn" @click="sharebox = true">帮他传播</button>
        <!-- <button class="order-btn" @click="goOrder" >
            查看项目
        </button> -->

        <div class="bx_box">
            <wx-open-launch-weapp
                id="launch-btn"
                appid="wx24eeb2ba0309b109"
                :path="adv_link_miniapp"
            >
                <script type="text/wxtag-template">
                    <style>
                        .ad-img{
                            width:100%;
                            max-width:100%;
                            overflow:hidden;

                        }
                     </style>

                    <img class="ad-img"  src="https://m.jisuchou.net/fund/minapp-ad.png" alt="" />
                </script>
            </wx-open-launch-weapp>
        </div>

        <!-- <a
                    v-if="adv_link"
                    href="javascript:;"
                    @click="onAdvClick"
                    class="bx_box"
                >
                    
                </a> -->

        <div class="share-box" v-if="sharebox" @click="sharebox = false">
            <img src="./image/share.png" alt="" />
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import utils from '@/common/js/utils';
import { shareProj, shareCtrl } from '@/common/js/mmcModule';
import { authChecker, tracking } from '@/common/js/mmcModule';
import saUtils from 'sensors-data-utils';
import groupUnifiedTracking from '@/common/js/groupUnifiedTracking';
import api from './api';

const params = utils.getRequestParams();
const pageKey = 'h5_jsb_paysuccess';
const session = groupUnifiedTracking.getSession();

export default {
    data() {
        return {
            sharebox: false,
            amount: params.amount,

            sysSession: session,
        };
    },
    computed: {
        // ...mapState(['jsbrnd']),

        adv_link() {
            return `https://m.qsebao.com/cbb/landpage?scene_name=pingan-property-pet-shjf--land-page&sku_name=pingan-property-pet-shjf&rc=orion.ct.baoxian.cwc_paysucc.20230524&ssid=${session.ssid}`;
        },
        adv_link_miniapp() {
            return `pages/tabBar/index?bi_cf=xcx.cw.qsnc.adposition.petfund.0828&ssid=${session.ssid}`;
        },
    },
    mounted() {
        const that = this;
        authChecker.check({ notneedphone: 1 }, () => {
            const { id } = params;
            api.getInfo(id).then(res => {
                if (res.code == 0) {
                    

                    shareCtrl.init({
                        title: '我正参加宠物筹5毛钱抢129元卫仕宠物口粮活动',
                        imgUrl: res.data.pet_image + '@!thumb.png',
                        desc: '点击链接，帮我助力1分钱',
                        url: `https://m.jisuchou.net/fund/activity/onepoint/detail?id=${id}`,
                        titleMoment:
                            '我正参加宠物筹5毛钱抢129元卫仕宠物口粮活动',
                        shareRecord: {
                            scene: pageKey,
                        },
                    });

                    this.reportData(res.data);
                }
            });
        });
    },
    methods: {
        goOrder() {
            const { projuuid } = params;
            this.$router.push({
                path: '/fund/project/detail',
                query: {
                    projuuid,
                },
            });
        },

        reportData(o) {
            tracking.init();

            saUtils.init(pageKey);
            if(o.is_main){
                saUtils.set('manager', 1);
            }else{
                saUtils.set('manager', 0);
            }
            

            saUtils.pv();
        },

        onAdvClick() {
            saUtils.click('paySuccessAdv');

            // location.href = this.adv_link;
            console.log(wx);
            // wx.navigateToMiniProgram({
            //     appId: 'wx24eeb2ba0309b109',
            //     path:
            //         'pages/tabBar/index?bi_cf=xcx.cw.qsnc.adposition.petfund.0828',
            // });
        },
    },
};
</script>
<style scoped lang="less">
.paysucc_box {
    width: 100vw;
    height: 100vh;
    padding: 5px 26px;
    .icon {
        width: 200px;
        height: 169px;
        margin: 0 auto;
        overflow-y: hidden;
        img {
            width: 100%;
        }
    }
    .dec {
        font-size: 17px;
        margin-top: 7px;
    }
    .share-btn {
        display: block;
        border: none;
        width: 100%;
        background-image: linear-gradient(90deg, #40b9ff 0%, #009bff 100%);
        box-shadow: 0 2px 15px 0 rgba(0, 155, 255, 0.4);
        border-radius: 4px;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        line-height: 50px;
        font-weight: bold;
        margin-top: 20px;
    }
    .order-btn {
        display: block;
        width: 100%;
        border: none;
        background: #ffffff;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        opacity: 0.87;
        font-size: 18px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        line-height: 50px;
        font-weight: bold;
        margin-top: 15px;
    }
    .share-box {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        img {
            width: 76%;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    .qrcode-box {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .mock {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.5);
        }
        .content {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 264px;
            height: auto;
            i {
                display: inline-block;
                width: 33px;
                height: 33px;
                background: url('./image/close.png');
                background-size: 100% 100%;
                float: right;
                transform: translateX(33px);
            }
            img {
            }
            p {
                font-size: 19px;
                color: #fff;
                margin-top: 20px;
            }
        }
    }
    .bx_box {
        margin-top: 30px;
        width: 100%;
        // height: 250px;
        border-radius: 8px;
        background-color: #fefefe;
        display: block;
        overflow: hidden;
        max-width: 100%;
        img {
            width: 100%;
            overflow: hidden;
            max-width: 100%;
        }
    }
}
</style>
